/*
 * @Author: sunzelong 526795980@qq.com
 * @Date: 2022-04-24 09:36:48
 * @LastEditors: sunzelong 526795980@qq.com
 * @LastEditTime: 2023-06-09 13:47:03
 * @FilePath: \education\ss-h5\src\config\sys-config.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 * 微信公众号 AppId 
 * 在自定义分享时起作用
 * 同时需要微信公众号后台，设置JS安全域名 h5.interescamp.com
 * */ 
export const appId = 'wxcbf9cb3ffa50b196'  
/**
 * 移动官网H5,域名前缀
 */
export const h5Url = 'https://h5.interescamp.com'
/**
 * api 接口地址
 */
 export const API = 'https://ss.webapi.interescamp.com/web/api'
//export const API = "http://192.168.111.9:2101/web/api"