/*
 * @Author: sunzelong 526795980@qq.com
 * @Date: 2022-04-18 08:36:45
 * @LastEditors: sunzelong 526795980@qq.com
 * @LastEditTime: 2023-06-08 15:36:04
 * @FilePath: \education\ss-h5\src\api\site.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

// 获取签名接口
export function getSign (data) {
  return request({
    url: 'visit/getSign',
    method: 'post',
    data
  })
}

// 获取站点
export function getSiteInfo (data) {
  return request({
    url: 'visit/load',
    method: 'post',
    data
  })
}